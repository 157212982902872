import { fetcher, put } from '.';
import { IFetcherResponse } from '../models';
import { ICreateBooking } from '../models/booking.interface';

export const createBooking = async (
    dto: ICreateBooking | null,
): Promise<IFetcherResponse<any>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/booking`, dto);
};

export const confirmPayment = async (
    queryString: string,
): Promise<IFetcherResponse<any>> => {
    if (!queryString) {
        Promise.reject('no data');
    }

    return fetcher(
        `${process.env.REACT_APP_BOOKING_API_URL}/payment${queryString}`,
    );
};

export const getOffer = async (id: string): Promise<IFetcherResponse<any>> => {
    if (!id) {
        Promise.reject('no data');
    }

    return fetcher(`${process.env.REACT_APP_BOOKING_API_URL}/offer?id=${id}`);
};
